<template>
  <div class="flex-row border-b dark:border-warm-gray-700">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-extrabold text-warm-gray-700 tracking-wider dark:text-warm-gray-100">Update Reviewer</h2>
    </div>
  </div>
  <div class="flex justify-center">

    <!-- form wrapper -->
    <div class="flex justify-center mt-14 mb-2 text-warm-gray-600 dark:text-warm-gray-200 w-full">
      <div class=" w-10/12 lg:w-7/12">

        <!-- header 1 -->
        <div class="flex justify-between items-end">
        <div class="flex justify-start items-center pl-2">
          <h2 class="text-warm-gray-600 font-bold text-lg dark:text-warm-gray-300">Update form</h2>
        </div>
        </div>  
        <!-- Course Information Form -->
        <div class="border shadow rounded-md p-4 mt-4 dark:border-warm-gray-700">
          
          <div class="mb-4">
            <div class="flex justify-between items-center">
              <label for="subject-id" class="font-semibold">Reviewer ID</label>
            </div>
            <input v-model="revId" id="course-id" class="dark:text-warm-gray-700 w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:border-warm-gray-700 bg-warm-gray-200 dark:bg-warm-gray-400" disabled />
          </div>

          <div class="mb-4">
            <div><label for="reviewer-name" class="font-semibold">Reviewer name</label></div>
            <input type="text" id="reviewer-name" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700" v-model="name" placeholder="Enter reviewer name" />
          </div>

          <div class="mb-4">
            <div><label for="reviewer-description" class="font-semibold">Description</label></div>
            <textarea id="reviewer-description" rows="3" v-model="description" placeholder="Enter reviewer description" class="w-full p-2 mt-2 border rounded focus:shadow-md focus:border-yellow-400 focus:outline-none dark:text-warm-gray-700"></textarea>
          </div>

          <div class="flex justify-end items-center mt-6">
            <button @click="$router.go(-1)" class="mr-2 bg-warm-gray-300 font-semibold py-2 px-4 rounded-md hover:bg-warm-gray-400 focus:bg-warm-gray-400 focus:outline-none dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600">Cancel</button>
            <button @click="updateReviewer()" class="bg-yellow-700 text-white font-semibold py-2 px-4 rounded-md hover:bg-yellow-800 focus:bg-yellow-800 focus:outline-none ">Save</button>
          </div>
        </div>
      </div>
    </div>

    <ProcessingModal v-if="isProccessing"/>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import { update, viewReviewer } from '../firebase';
import ProcessingModal from '@/components/ProcessingModal';
import swal from 'sweetalert2';

const props = defineProps({
  reviewerId: {type: String, require: true }
})

const reviewer = ref(null);
const isProccessing = ref(false);

// form
const revId = ref('');
const name = ref('');
const description = ref('');

onMounted(async () => {
  
  revId.value = props.reviewerId;

  let res = await viewReviewer(props.reviewerId);

  if(!res.isError){
    name.value = res.data.name;
    description.value = res.data.description;
  }

})

const updateReviewer = async () => {

  if (name.value && description.value ) {
    isProccessing.value = true;
    await update({
      id: revId.value,
      name: name.value,
      description :description.value
    })

    isProccessing.value = false;
    swal.fire(
        'Success!',
        `Reviewer has been updated.`,
        'success'
    );
  }else{
    alert('Please fill out all fields!');
  }

}

</script>